import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VersionApiService } from '@ev-portals/cp/frontend/shared/api-client';
import { Environment } from '@ev-portals/cp/frontend/shared/util';
import { map, take, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  selector: 'cp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  readonly #versionApiService = inject(VersionApiService);
  readonly #environment = inject(Environment);

  // Navigation
  readonly sections: {
    title: string;
    items: { title: string; link: string; dataCy?: string }[];
  }[] = [
    {
      title: $localize`BASF`,
      items: [
        {
          title: $localize`Performance Chemicals`,
          link: 'https://www.basf.com/global/en/products/segments/industrial_solutions/performance_chemicals',
        },
        {
          title: $localize`Careers`,
          link: 'https://www.basf.com/global/en/careers',
        },
        {
          title: $localize`Media`,
          link: 'https://www.basf.com/global/en/media',
        },
        {
          title: $localize`Sustainability`,
          link: 'https://www.basf.com/global/en/who-we-are/sustainability/we-drive-sustainable-solutions/circular-economy/mass-balance-approach/biomass-balance',
        },
      ],
    },
    {
      title: $localize`Industries`,
      items: [
        {
          title: $localize`Automotive & Transportation`,
          link: `/products?remoteFilters=%7B%22IndustryFilter%22%3A%22${this.#environment.footerLinks.automotiveTransportation}%22%7D`,
        },
        {
          title: $localize`Mining Solutions`,
          link: `/products?remoteFilters=%7B%22IndustryFilter%22%3A%22${this.#environment.footerLinks.miningSolutions}%22%7D`,
        },
        {
          title: $localize`Nutrition`,
          link: `/products?remoteFilters=%7B%22IndustryFilter%22%3A%22${this.#environment.footerLinks.nutrition}%22%7D`,
        },
        {
          title: $localize`Oilfield Chemicals`,
          link: `/products?remoteFilters=%7B%22IndustryFilter%22%3A%22${this.#environment.footerLinks.oilfieldChemicals}%22%7D`,
        },
        {
          title: $localize`Plastic Additives`,
          link: `/products?remoteFilters=%7B%22IndustryFilter%22%3A%22${this.#environment.footerLinks.plasticAdditives}%22%7D`,
        },
      ],
    },
    {
      title: $localize`Products`,
      items: [
        {
          title: $localize`Products Finder`,
          link: `/products`,
        },
        {
          title: $localize`Sustainability Offerings`,
          link: `/products?remoteFilters=%7B%22BmbCertificateFilter%22:true%7D`,
        },
      ],
    },
    {
      title: $localize`Customer service`,
      items: [
        {
          title: $localize`Contact us`,
          link: `${this.#environment.baseUrl}/info/contact`,
          dataCy: 'contact-us',
        },
      ],
    },
  ];
  readonly bottomLinks: {
    title: string;
    link: string | null;
    target: string | null;
    dataCy: string;
    class?: string;
  }[] = [
    {
      title: $localize`Cookie Settings`,
      link: null,
      target: null,
      dataCy: 'cookie-settings',
      class: 'optanon-toggle-display',
    },
    {
      title: $localize`Disclaimer`,
      link: 'https://www.basf.com/global/en/legal/disclaimer',
      target: '_blank',
      dataCy: 'disclaimer',
    },
    {
      title: $localize`Privacy Policy`,
      link: `/info/privacy-policy`,
      target: '_blank',
      dataCy: 'privacy-policy',
    },
    {
      title: $localize`Terms of use`,
      link: `/info/terms-of-use`,
      target: '_blank',
      dataCy: 'terms-of-use',
    },
    {
      title: $localize`Imprint`,
      link: 'https://www.basf.com/global/en/legal/credits',
      target: '_blank',
      dataCy: 'imprint',
    },
  ];

  // Information
  readonly currentYear = new Date().getFullYear();
  readonly stage = this.#environment.environment;
  readonly $apiHash = signal<string>('');

  readonly appVersion = this.#environment.version;
  readonly gitHash = this.#environment.hash;

  constructor() {
    this.#versionApiService
      .getVersion()
      .pipe(
        take(1),
        map(version => version.hash),
        tap(hash => {
          this.$apiHash.set(hash);

          console.table({
            MyPerformanceChemicals: this.appVersion,
            Environment: this.stage,
            'Frontend Version': this.gitHash,
            'Backend Version': hash,
          });
        }),
      )
      .subscribe();
  }
}
