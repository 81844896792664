<ev-modal-dialog
  [$title]="$title()"
  [$subtitle]="$subtitle()"
  [$hideCloseButton]="$hideCloseButton()"
  (closeDialog)="onClose()"
>
  @if ($loading()) {
    <ev-loading-overlay></ev-loading-overlay>
  }

  <form (ngSubmit)="onSubmit()">
    <div class="flex flex-col scroll">
      @for (item of $userAddresses(); track item.key) {
        <div
          class="ev-row cursor-pointer flex flex-row gap-3 p-3"
          data-cy="location-select-radio-button"
          (click)="groupControl.setValue(item.key)"
        >
          <p-radioButton
            [formControl]="groupControl"
            [value]="item.key"
            [autofocus]="false"
          >
          </p-radioButton>

          <div class="flex flex-col">
            <span>
              {{ item.name }} ({{ item.key }})
              {{ item?.key === $currentlySelectedAddressType() ? " ✅" : "" }}
            </span>
            <small>{{ formatAddress(item.address) }}</small>
          </div>
        </div>
      }
    </div>
  </form>

  <p-button
    footer
    styleClass="!w-full !pt-3"
    [label]="selectLabel"
    [loading]="$loading()"
    [disabled]="groupControl.invalid"
    (onClick)="onSubmit()"
    data-cy="location-select-button"
  ></p-button>
</ev-modal-dialog>
