import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AngularModule } from '@atoms/angular';
import { AnalyticsService, NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { ModalDialogComponent } from '@ev-portals/ev/frontend/ui-library';
import { Button } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'cp-account-register-dialog',
  standalone: true,
  imports: [
    CommonModule,
    AngularModule,
    ReactiveFormsModule,
    DividerModule,
    ModalDialogComponent,
    DropdownModule,
    Button,
  ],
  templateUrl: './account-register-dialog.component.html',
  styleUrls: ['./account-register-dialog.component.scss'],
})
export class AccountRegisterDialogComponent {
  $title = input<string>();
  $subtitle = input<string>();
  $origin = input<string>();

  closeDialog = output<void>();

  #analyticsService = inject(AnalyticsService);
  #navigationService = inject(NavigationService);
  #route = inject(ActivatedRoute);

  #redirectUrl: string | null;

  productGroupLabel = $localize`Please select the product group`;
  readonly productGroupOptions: { key: string; label: string; link: string }[] = [
    {
      key: 'oppanol',
      label: 'OPPANOL®',
      link: 'https://automotive-transportation.basf.com/global/en/fuel-and-lubricants/fuel-and-lubricant-solutions/polyisobutenes--pib-/oppanol_more-than-just-polyisobutene/Portal_Registration.html',
    },
    {
      key: 'glysantin',
      label: 'GLYSANTIN®',
      link: 'https://www.glysantin.com/global/en/product-finder/where-to-buy/Portal-Registration.html',
    },
    {
      key: 'plastic-additives',
      label: 'Plastic Additives',
      link: 'https://plastics-rubber.basf.com/global/en/plastic_additives/registration.html',
    },
    {
      key: 'oilfield-chemicals',
      label: 'Oilfield Chemicals',
      link: 'https://energy-resources.basf.com/global/en/oilfield-chemicals/portal-registration.html',
    },
    {
      key: 'mining-solutions',
      label: 'Mining Solutions',
      link: 'https://energy-resources.basf.com/global/en/mining_solutions/portal-registration.html',
    },
    {
      key: 'lubricant-components',
      label: 'Lubricant Components',
      link: 'https://automotive-transportation.basf.com/global/en/fuel-and-lubricants/fuel-and-lubricant-solutions/components-for-lubricants/customer-portal-registration.html',
    },
  ];

  fg = inject(FormBuilder).group({
    selectedProductGroupLink: ['', Validators.required],
  });

  constructor() {
    effect(() => {
      const productGroupLink = this.productGroupOptions.find(
        option => option.key === this.$origin(),
      )?.link;

      this.#analyticsService.trackEvent(
        'register',
        'userRegistrationDialogOpen',
        'action',
        JSON.stringify({ origin: this.$origin() || null }),
      );

      if (productGroupLink) {
        this.fg.controls.selectedProductGroupLink.setValue(productGroupLink);
      }
    });

    this.#redirectUrl = this.#route.snapshot.queryParamMap.get('redirectUrl');
  }

  onClose(closeType: string): void {
    this.#analyticsService.trackEvent(
      'register',
      'userRegistrationDialogClose',
      'action',
      JSON.stringify({ closeType, origin: this.$origin() || null }),
    );

    this.closeDialog.emit();
  }

  onOpenProductGroupRegisterPage(): void {
    const registerLink = this.fg.controls.selectedProductGroupLink.value;

    if (registerLink) {
      this.#analyticsService.trackEvent(
        'register',
        'productGroupRegistrationPageOpen',
        'action',
        JSON.stringify({
          origin: this.$origin() || null,
          selectedProductGroup: this.productGroupOptions.find(
            option => option.link === registerLink,
          )?.key,
        }),
      );
      window.open(registerLink, '_blank');
    }
  }

  onLogin(): void {
    if (this.#redirectUrl) {
      this.#navigationService.setOriginalDestination(this.#redirectUrl);
    }

    this.#analyticsService.trackEvent(
      'register',
      'userRegistrationDialogLogin',
      'action',
      JSON.stringify({ origin: this.$origin() || null }),
    );

    this.#navigationService.navigateToLogin();
  }
}
