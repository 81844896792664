import { CommonModule } from '@angular/common';
import { Component, computed, effect, input, output, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AngularModule } from '@atoms/angular';
import {
  AccountAddress,
  AccountAddressItem,
  SelectedLocationDto,
} from '@ev-portals/cp/frontend/shared/api-client';
import { AddressType } from '@ev-portals/cp/frontend/shared/auth/util';
import { LoadingOverlayComponent, ModalDialogComponent } from '@ev-portals/ev/frontend/ui-library';
import { Button } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
  selector: 'cp-location-selection-dialog',
  standalone: true,
  imports: [
    CommonModule,
    AngularModule,
    FormsModule,
    ReactiveFormsModule,
    ModalDialogComponent,
    LoadingOverlayComponent,
    Button,
    RadioButtonModule,
  ],
  templateUrl: './location-selection-dialog.component.html',
  styleUrls: ['./location-selection-dialog.component.scss'],
})
export class LocationSelectionDialogComponent {
  $title = input<string>();
  $subtitle = input<string>();
  $addressType = input<AddressType>();
  $userAddresses = input<AccountAddressItem[]>();
  $currentlySelectedLocation = input<SelectedLocationDto | null | undefined>();
  $hideCloseButton = input<boolean>(false);

  closeDialog = output<void>();
  addressSelection = output<SelectedAddress>();

  public $loading = signal<boolean>(true);

  $currentlySelectedAddressType = computed(() => {
    const currentlySelectedLocation = this.$currentlySelectedLocation();
    const addressType = this.$addressType();

    if (currentlySelectedLocation && addressType) {
      return currentlySelectedLocation[addressType];
    }

    return null;
  });

  emptyUserAddresses: AccountAddressItem[] = [];

  readonly selectLabel = $localize`Select`;

  constructor() {
    effect(
      () => {
        const userAddresses = this.$userAddresses();
        const currentlySelectedLocation = userAddresses?.find(
          address => this.$currentlySelectedAddressType() === address.key,
        );

        if (currentlySelectedLocation) {
          this.groupControl.setValue(currentlySelectedLocation.key);
          this.$loading.set(false);
        } else if (userAddresses) {
          if (userAddresses.length === 1) {
            // Automatically select the only address if there is only one (but continue showing the loading spinner)
            this.groupControl.setValue(userAddresses[0].key);
            this.onSubmit();
          } else if (userAddresses.length > 1) {
            this.$loading.set(false);
          }
        }
      },
      { allowSignalWrites: true },
    );
  }

  public groupControl = new FormControl<string>('', Validators.required);
  public onSubmit(): void {
    const { value } = this.groupControl;

    if (!value) return;

    const selectedAddress = {
      address: this.$userAddresses()?.find(address => address.key === value) as AccountAddressItem,
      addressType: this.$addressType() as AddressType,
    };
    this.addressSelection.emit(selectedAddress);
    this.$loading.set(true);
    this.groupControl.reset();
  }

  onClose(): void {
    this.closeDialog.emit();
  }

  formatAddress(address: AccountAddress): string {
    const { street = '', zip = '', city = '' } = address;
    return [street, zip, city].filter(val => !!val).join(' ');
  }
}

export interface SelectedAddress {
  address: AccountAddressItem;
  addressType: AddressType;
}
