import { CommonModule } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthenticationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { CookieService, FeedbackMessageService } from '@ev-portals/cp/frontend/shared/util';
import {
  AlertDialogComponent,
  ConfirmDialogComponent,
  NotificationComponent,
  PromptDialogComponent,
} from '@ev-portals/ev/frontend/ui-library';
import { DialogService } from '@ev-portals/ev/frontend/util';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  standalone: true,
  selector: 'cp-dynamic-content',
  imports: [
    CommonModule,
    PromptDialogComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    NotificationComponent,
    ToastModule,
  ],
  providers: [MessageService],
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.scss'],
})
export class DynamicContentComponent {
  #authenticationService = inject(AuthenticationService);
  #messageService = inject(MessageService);
  #cookieService = inject(CookieService);
  #destroyRef = inject(DestroyRef);
  feedbackMessageService = inject(FeedbackMessageService);
  dialogService = inject(DialogService);
  customRenderer = 0;

  constructor() {
    this.#listenToImpersonationMode();
    this.#listenToInternalUser();
    this.#listenToLogoutSuccess();

    effect(() => {
      const message = this.feedbackMessageService.$alertMessage();
      if (message) {
        this.#messageService.add({
          id: message.id,
          severity: message.type,
          life: message.displaySeconds ? message.displaySeconds * 1000 : 5000,
          detail: message.message,
          contentStyleClass: 'flex flex-row items-center',
        });
      }
    });
  }

  #listenToInternalUser(): void {
    this.#authenticationService.user$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(user => {
      user?.isInternalUser
        ? this.feedbackMessageService.addInternalUserNotification()
        : this.feedbackMessageService.removeInternalUserNotification();
    });
  }

  #listenToImpersonationMode(): void {
    // Create a computed value that combines all conditions
    const impersonationState = computed(() => {
      // Check if impersonation mode is active
      const isImpersonating = this.#authenticationService.$impersonationMode();

      if (!isImpersonating) {
        return null; // Not in impersonation mode
      }

      // Check if login is still pending - we want to wait until it's false
      const isLoginPending = this.#authenticationService.$loginPending();

      if (isLoginPending) {
        return undefined; // Login is still pending, so wait
      }

      // Login is complete, now get the IAM ID
      // const threeIamId = this.#authenticationService.$impersonatedUserThreeIamId();
      const threeIamId = this.#cookieService.getImpersonationToken();

      // Process the IAM ID to get the type
      const impersonateModeType = threeIamId?.split('_')[1] || 'support';
      return impersonateModeType ? ` (${impersonateModeType})` : '';
    });

    effect(
      () => {
        const result = impersonationState();

        if (result === null) {
          // Not in impersonation mode
          this.feedbackMessageService.removeImpersonationNotification();
        } else if (result !== undefined) {
          // In impersonation mode, login is NOT pending, and IAM ID is processed
          this.feedbackMessageService.addImpersonationNotification(result);
        }
        // If undefined, we're in impersonation mode but login is still pending
      },
      { allowSignalWrites: true },
    );
  }

  #listenToLogoutSuccess(): void {
    this.#authenticationService.logoutSuccess$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.feedbackMessageService.showSuccessMessage(
            $localize`You have been successfully logged out.`,
          );
        },
        error: () => {
          this.feedbackMessageService.showErrorMessage();
        },
      });
  }
}
